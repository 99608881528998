import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import queryString from "query-string";

import { Link } from "gatsby";

// seo
import Seo from "../components/Seo";

// config
import config from "../config";

// App Header
// import AppHeaderTwinApp from "../components/AppHeader/AppHeaderTwinApp";
import AppHeader from "../components/AppHeader/AppHeader";

// components
import Testimonial from "../components/Testimonials/customer-testimonials";

// css
import * as styles from "../components/Testimonials/Testimonials.module.css";

const FilterTab = [
  { id: 1, name: "All", value: "all", desc: "All" },
  {
    id: 2,
    name: "Stopped Insulin",
    value: "stopped insulin",
    desc: "STOPPED INSULIN",
  },
  {
    id: 3,
    name: "Stopped Meds",
    value: "stopped meds",
    desc: "STOPPED MEDICATIONS",
  },
  {
    id: 4,
    name: "10 years diabetic",
    value: "10 years diabetic",
    desc: "10 + YEARS OF DIABETES",
  },
  {
    id: 5,
    name: "Reduced Weight",
    value: "reduced weight",
    desc: "REDUCED WEIGHT",
  },
];

const TestimonialsMember = props => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [filterData, setFilterData] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const { twinApp, appHeader, clientId } = queryString.parse(
    props.location.search
  );

  useEffect(() => {
    const getData = async () => {
      const options = {
        method: "get",
        url: config.DX_API_BASE_URL + "/api/testimonials",
        headers: {
          Authorization: `Bearer ${config.JWT_TOKEN}`,
        },
      };

      try {
        setLoading(true);
        const response = await axios(options);
        setData(response.data.results);
        setFilterData(response.data.results);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, []);

  const handleFilter = value => {
    setActiveTab(value);
    if (value === "All") {
      setFilterData(data);
    } else {
      const newValue = value.trim();
      if (newValue) {
        const newList = data.filter(item => {
          return item.category.toLowerCase().includes(value.toLowerCase());
        });
        setFilterData(newList);
      } else {
        // restore
        setFilterData(data);
      }
    }
  };

  return (
    <div>
      <Seo
        title="Diabetes Quiz | Diabetes Reversal Program"
        description="Diabetes Quiz. 1000's have reversed their diabetes using Twin's proven AI based precision treatment."
      />

      {/* Start ---> Header for Twin App */}
      {/* {appHeader === "no" && twinApp === "yes" && (
        <AppHeaderTwinApp
          text=""
          isBack={true}
          backUrl="mobile_back"
          bgColor="bgLightgray"
        />
      )} */}

      {appHeader === "mobile" && twinApp === "yes" && (
        <AppHeader text="" isBack={true} backUrl="mobile_back" />
      )}
      {/* End ---> Header for Twin App */}

      <div className="bgLightgray">
        <div className="container twinContainer">
          <div
            className={
              appHeader === "no" && twinApp === "yes"
                ? `${styles.main_header_twinApp}`
                : `${styles.main_header}`
            }
          >
            {/* {
              twinApp && twinApp ==="yes" &&  
              <div className="row">
            <div className="col-12 d-md-none pb-3">
                <span role={"presentation"} onClick = {handleBackClick} className="icon-back smallIcon" />
            </div>
          </div>
            }   */}
            <div className="d-flex justify-content-between">
              <div className={styles.succStor}>Customer success stories</div>
              {twinApp && twinApp === "yes" ? (
                ""
              ) : (
                <Link className="navLink" to={"/"}>
                  <span
                    role="presentation"
                    className="d-md-none icon-cross smallIcon"
                  />
                </Link>
              )}
            </div>
            <div className={styles.header_filter}>
              {FilterTab.map((item, index) => (
                <button
                  key={index}
                  className={`${styles.custom_btn} ${
                    activeTab === item.desc && styles.custom_btn_active
                  }`}
                  onClick={() => handleFilter(item.desc)}
                >
                  <span>{item.name}</span>
                </button>
              ))}
            </div>
            <div className="d-flex">
              <span className={styles.len}> {filterData.length} Stories </span>
            </div>
          </div>
          {loading ? (
            <div className={styles.spiner_container}>
              <Spinner
                animation="border"
                role="status"
                className={styles.spinner}
              />
            </div>
          ) : (
            <div className="row">
              {filterData.length > 0
                ? filterData.map((data, i) => {
                    return (
                      <Testimonial
                        rating={data.rating}
                        age={data.age}
                        id={data.id}
                        bloodSugarLevelAfter={data.bloodSugarLevelAfter}
                        bloodSugarLevelBefore={data.bloodSugarLevelBefore}
                        category={data.category}
                        city={data.city}
                        headline={data.headline}
                        headline2={data.headline2}
                        clientPhoto={data.clientPhoto}
                        name={data.name}
                        noOfMedsAfter={data.noOfMedsAfter}
                        noOfMedsBefore={data.noOfMedsBefore}
                        reviewPlatform={data.reviewPlatform}
                        platformLink={data.platformLink}
                        index={i}
                        graphLink={data.graphLink}
                        twinApp={twinApp}
                        appHeader={appHeader}
                        clientId={clientId}
                        noOfDaysSugarNormalised={data.noOfDaysSugarNormalised}
                        length={filterData.length - 1}
                      />
                    );
                  })
                : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsMember;
