// extracted by mini-css-extract-plugin
export var breadCrumb = "Testimonials-module--breadCrumb--dFAF8";
export var brownText = "Testimonials-module--brownText--IY83A";
export var bs_img = "Testimonials-module--bs_img--sDiAz";
export var btntxt = "Testimonials-module--btntxt--mUNtN";
export var ctaHeader = "Testimonials-module--ctaHeader--+4l3Y";
export var custText4 = "Testimonials-module--custText4--5-oc5";
export var custom_btn = "Testimonials-module--custom_btn--opPlt";
export var custom_btn_active = "Testimonials-module--custom_btn_active--bmIVG";
export var formInput = "Testimonials-module--formInput--XiEDt";
export var form_p = "Testimonials-module--form_p--27FV2";
export var greenPillBox = "Testimonials-module--greenPillBox--k0iU2";
export var headerSubText = "Testimonials-module--headerSubText--oBwla";
export var headerText = "Testimonials-module--headerText--EeePG";
export var header_filter = "Testimonials-module--header_filter--vyNJQ";
export var heading1 = "Testimonials-module--heading1--AC6GW";
export var imgCo = "Testimonials-module--imgCo--JfLav";
export var len = "Testimonials-module--len--fqaMB";
export var main_header = "Testimonials-module--main_header--WNCzj";
export var main_header_twinApp = "Testimonials-module--main_header_twinApp--gshpu";
export var progText1 = "Testimonials-module--progText1--yz4VH";
export var progText2 = "Testimonials-module--progText2--PBPmL";
export var progressTxt = "Testimonials-module--progressTxt--FZ-Z4";
export var readMore = "Testimonials-module--readMore--S+XqU";
export var result_heading = "Testimonials-module--result_heading--7bg34";
export var review = "Testimonials-module--review--68zCV";
export var review2 = "Testimonials-module--review2--doMMQ";
export var spiner_container = "Testimonials-module--spiner_container--IOlrV";
export var spinner = "Testimonials-module--spinner--f8z7P";
export var submitBtn = "Testimonials-module--submitBtn---rFDk";
export var succStor = "Testimonials-module--succStor--cZfok";
export var successStor = "Testimonials-module--successStor--Z92H0";
export var testiDtls_backBtn_twinApp = "Testimonials-module--testiDtls_backBtn_twinApp--H1e0H";
export var testiDtls_twinApp = "Testimonials-module--testiDtls_twinApp--WjpON";
export var testimonialCon = "Testimonials-module--testimonialCon--A-y69";
export var testimonialHeader = "Testimonials-module--testimonialHeader--yOA8F";
export var testimonialSubHeader = "Testimonials-module--testimonialSubHeader--KDHn3";
export var titleText = "Testimonials-module--titleText--cjiH9";