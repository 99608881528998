import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { noOfDayAndMonthConversion } from "../../utils";

// config
import config from "../../config";

// css
import * as styles from "../Testimonials/Testimonials.module.css";

//images
import { Image } from "cloudinary-react";

function Testimonial(props) {
  return (
    <>
      <div
        className={`col-lg-6 ${styles.testimonialCon}`}
        style={{
          marginBottom: props.index === props.length ? "70px" : "10px",
        }}
      >
        <hr />

        <div className="row">
          <div className="col-8">
            <div className="col-12">
              <div className={styles.headerText}>
                {props.headline && props.headline}
              </div>
            </div>
            <div className="col-12">
              <div className={styles.headerSubText}>
                {props.age} years old, {props.city}
              </div>
            </div>
          </div>
          <div className="col-4 d-flex justify-content-end">
            {props.clientPhoto && props.clientPhoto && (
              <div>
                <Image
                  secure="true"
                  cloudName={config.CLOUDINARY_NAME}
                  publicId={props.clientPhoto}
                  dpr="auto"
                  responsive
                  width="auto"
                  crop="scale"
                  responsiveUseBreakpoints="true"
                  fetchFormat="webp"
                  quality="auto"
                  className={styles.bs_img}
                />{" "}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="row">
              <div className="col-6 col-md-6 col-lg-6">
                <p className={styles.custText4}>Start of the program</p>
              </div>
              <div className="col-6 col-md-6 col-lg-6 d-flex justify-content-end">
                <span className={styles.custText4}>
                  {props.noOfDaysSugarNormalised &&
                    noOfDayAndMonthConversion(props.noOfDaysSugarNormalised)}
                </span>
              </div>
            </div>
          </div>
        </div>

        {props.bloodSugarLevelAfter && props.bloodSugarLevelAfter && (
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="row align-items-center">
                <div className="d-flex">
                  <div className="pe-3 mt-2">
                    <span className="smallIcon icon-drop" />
                  </div>
                  <div className="d-flex mt-2">
                    <p className={styles.progText1}>Blood Sugar: </p>
                    <p className={`${styles.progText2} ps-1`}>
                      {props.bloodSugarLevelBefore &&
                        props.bloodSugarLevelBefore}
                    </p>
                  </div>
                  <div className="ps-2 pt-1 pb-2 mt-2 pe-2">
                    <StaticImage
                      src="../../images/home/bigArrow.svg"
                      alt=""
                      placeholder="blurred"
                    />
                  </div>
                  <div
                    className={`rounded-pill d-flex align-items-center justify-content-center ${styles.greenPillBox} ms-2`}
                  >
                    <span>
                      {props.bloodSugarLevelAfter && props.bloodSugarLevelAfter}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {props.noOfMedsBefore && props.noOfMedsBefore && (
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="row align-items-center">
                <div className="d-flex">
                  <div className="pe-3 mt-2">
                    <span className="smallIcon icon-tablet" />
                  </div>
                  <div className="d-flex mt-2">
                    <p className={styles.progText1}>Medicines: </p>
                    <p className={`${styles.progText2} ps-1`}>
                      {props.noOfMedsBefore && props.noOfMedsBefore}
                    </p>
                  </div>
                  <div className="ps-2 pt-1 mt-2 pe-2">
                    <StaticImage
                      src="../../images/home/bigSmallArrow.svg"
                      alt=""
                      placeholder="blurred"
                    />
                  </div>
                  <div
                    className={`rounded-pill d-flex align-items-center justify-content-center ${styles.greenPillBox} ms-4`}
                  >
                    <span>{props.noOfMedsAfter && props.noOfMedsAfter}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row justify-content-center">
          <div className="col-12">
            <div className={styles.titleText}>
              {props.headline2 &&
                props.headline2.substr(0, props.headline2.length / 2) + "..."}
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="d-inline-flex justify-content-center align-items-center pt-3 pb-2">
            <Link
              to={`/testimonial-details/?id=${props.id}${
                (props.appHeader === "no" || props.appHeader === "mobile") &&
                props.twinApp === "yes"
                  ? `&appHeader=${props.appHeader}&twinApp=${props.twinApp}&clientId=${props.clientId}`
                  : ""
              }`}
              className={`navLink`}
            >
              <div className="transparentBtn">
                <p className={`pt-2 pb-2`}>Read More</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
export default Testimonial;
